import React, { useCallback, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  TopBar,
  ActionList,
  // Icon,
  // VisuallyHidden,
  Frame,
  Navigation,
} from '@shopify/polaris';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  HomeMajor,
  OrdersMajor,
  ShipmentMajor,
  CustomersMajor,
  ProfileMajor,
  LogOutMinor,
  OrderStatusMinor,
  PackageMajor,
  NotificationMajor,
  SettingsMajor,
  ProductsMajor,
  PhoneMajor,
  AnalyticsMajor,
  LanguageMinor,
  ReturnMinor,
  AbandonedCartMajor,
  AddProductMajor,
  SendMajor,
  ChatMajor,
  LinkMinor,
  // MergeMinor,
} from '@shopify/polaris-icons';

import { useHistory } from 'react-router-dom';

const AppTopBar = ({ children, isAuthenticated, auth }) => {
  const history = useHistory();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  // const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const location = useLocation();
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const skipToContentRef = useRef(null);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  // const changeRoute = useCallback(
  //   (pathname) => {
  //     history.push(pathname);
  //   },
  //   [history]
  // );

  // const toggleIsSecondaryMenuOpen = useCallback(
  //   () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
  //   []
  // );

  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value);
    setIsSearchActive(value.length > 0);
  }, []);

  const getLetters = () => {
    let firstName = auth?.user?.first_name?.split('');
    let lastName = auth?.user?.last_name?.split('');
    if (firstName?.length || lastName?.length) {
      return {
        letters: `${firstName?.length ? firstName[0] : ''}${
          lastName?.length ? lastName[0] : ''
        }`,
      };
    }

    return {
      letters: '',
    };
  };

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              url: '/dashboard/profile',
              content: 'Profile',
            },
          ],
        },
        {
          items: [
            {
              url: '/logout',
              content: 'Logout',
              icon: LogOutMinor,
            },
          ],
        },
      ]}
      name={`${auth?.user?.first_name} ${auth?.user?.last_name} `}
      detail='Nutrifactor'
      initials={`${getLetters().letters}`}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { url: 'https://www.nutrifactor.com.pk', content: 'Nutrifactor' },
        {
          url: 'https://www.nutrifactor.com.pk/pages/contact-us',
          content: 'Contact Us',
        },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder='Search'
      showFocusBorder
    />
  );

  // const secondaryMenuMarkup = (
  //   <TopBar.Menu
  //     activatorContent={
  //       <span>
  //         <Icon source={QuestionMarkMajor} />
  //         <VisuallyHidden>Nutrifactor</VisuallyHidden>
  //       </span>
  //     }
  //     open={isSecondaryMenuOpen}
  //     onOpen={toggleIsSecondaryMenuOpen}
  //     onClose={toggleIsSecondaryMenuOpen}
  //     actions={[
  //       {
  //         items: [{ content: "Nutrifactor" }],
  //       },
  //     ]}
  //   />
  // );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      // secondaryMenu={secondaryMenuMarkup}
      searchResultsVisible={isSearchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkdown = (
    <Navigation location={location.pathname}>
      <Navigation.Section
        title='General'
        items={[
          {
            url: '/dashboard',
            label: 'Dashboard',
            icon: HomeMajor,
          },
        ]}
      />

      <Navigation.Section
        separator
        title='Admin'
        items={[
          {
            url: '/dashboard/orders',
            // onClick: () => changeRoute('/dashboard/orders'),
            label: 'Orders',
            icon: OrdersMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('orders')
                ? true
                : false,
          },
          {
            url: '/dashboard/analytics',
            // onClick: () => changeRoute('/dashboard/orders'),
            label: 'Orders Analytics',
            icon: OrdersMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('orders')
                ? true
                : false,
          },
          {
            url: '/dashboard/booked-packets',
            // onClick: () => changeRoute('/dashboard/booked-packets'),
            label: 'Booked Packets',
            icon: ShipmentMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('booked-orders')
                ? true
                : false,
          },
          {
            url: '/dashboard/staff',
            // onClick: () => changeRoute('/dashboard/staff'),
            label: 'Manage Staff',
            icon: CustomersMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,
          },
          {
            url: '/dashboard/call-settings',
            // onClick: () => changeRoute('/dashboard/call-settings'),
            label: 'Call Settings',
            icon: PhoneMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,
          },
          // {
          //   url: '/dashboard/sms',
          //   label: 'SMS Status',
          //   icon: ChatMajor,
          // },
          {
            url: '/dashboard/sms-settings',
            // onClick: () => changeRoute('/dashboard/sms-settings'),
            label: 'SMS Settings',
            icon: SettingsMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,
          },
          {
            url: '/dashboard/survey-settings',
            // onClick: () => changeRoute('/dashboard/survey-settings'),
            label: 'Survey Settings',
            icon: SettingsMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,

            subNavigationItems: [
              {
                url: '/dashboard/survey-data',
                // onClick: () => changeRoute('/dashboard/survey-data'),
                label: 'Survey Data',
                icon: SettingsMajor,
                disabled: auth?.user?.role !== 'admin' ? true : false,
              },
              {
                url: '/dashboard/survey-analytics',
                // onClick: () => changeRoute('/dashboard/survey-analytics'),
                label: 'Survey Analytics',
                icon: AnalyticsMajor,
                disabled: auth?.user?.role !== 'admin' ? true : false,
              },
              {
                url: '/dashboard/records',
                // onClick: () => changeRoute('/dashboard/records'),
                label: 'Records',
                icon: AnalyticsMajor,
                disabled: auth?.user?.role !== 'admin' ? true : false,
              },
            ],
          },
          {
            url: '/dashboard/templates',
            // onClick: () => changeRoute('/dashboard/templates'),
            label: 'Templates',
            icon: AnalyticsMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,

            subNavigationItems: [
              {
                url: '/dashboard/tempRecords',
                // onClick: () => changeRoute('/dashboard/tempRecords'),
                label: 'Template Records',
                icon: AnalyticsMajor,
                disabled: auth?.user?.role !== 'admin' ? true : false,
              },
            ],
          },
          {
            url: '/dashboard/stats',
            // onClick: () => changeRoute('/dashboard/stats'),
            label: 'Staff Stats',
            icon: OrderStatusMinor,
            disabled: auth?.user?.role !== 'admin' ? true : false,
          },
          {
            url: '/dashboard/leopard',
            // onClick: () => changeRoute('/dashboard/leopard'),
            label: 'Leopard Settings',
            icon: PackageMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,
          },
          {
            url: '/dashboard/restock',
            // onClick: () => changeRoute('/dashboard/leopard'),
            label: 'Restock',
            icon: NotificationMajor,
            disabled: auth?.user?.role !== 'admin' ? true : false,
            subNavigationItems: [
              {
                url: '/dashboard/restock/products',
                // onClick: () =>
                //   changeRoute('/dashboard/return-shipments-records'),
                label: 'Products',
                icon: ProductsMajor,
                disabled: auth?.user?.role !== 'admin' ? true : false,
              },
              {
                url: '/dashboard/restock/subscribers',
                // onClick: () =>
                //   changeRoute('/dashboard/return-shipments-records'),
                label: 'Subscribers',
                icon: CustomersMajor,
                disabled: auth?.user?.role !== 'admin' ? true : false,
              },
            ],
          },
        ]}
      />

      <Navigation.Section
        separator
        title='CSR'
        items={[
          // {
          //   url: '/dashboard/merge-orders',
          //   // onClick: () => changeRoute('/dashboard/order-products'),
          //   label: 'Merge Orders',
          //   icon: MergeMinor,
          //   disabled:
          //     auth?.user?.role !== 'admin' &&
          //     !auth?.user?.roles.includes('book-orders')
          //       ? true
          //       : false,
          // },
          {
            url: '/dashboard/order-products',
            // onClick: () => changeRoute('/dashboard/order-products'),
            label: 'Search Orders By Products',
            icon: ProductsMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('get-product-by-orders')
                ? true
                : false,
          },
          {
            url: '/dashboard/order-products',
            // onClick: () => changeRoute('/dashboard/order-products'),
            label: 'Search Orders By Products',
            icon: ProductsMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('get-product-by-orders')
                ? true
                : false,
          },
          {
            url: '/dashboard/orderbyid',
            // onClick: () => changeRoute('/dashboard/orderbyid'),
            label: 'Track Order',
            icon: ShipmentMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('track-orders')
                ? true
                : false,
          },
          {
            url: '/dashboard/return-shipments',
            // onClick: () => changeRoute('/dashboard/return-shipments'),
            label: 'Return Shipments',
            icon: ReturnMinor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('upload-orders-return')
                ? true
                : false,

            subNavigationItems: [
              {
                url: '/dashboard/return-shipments-records',
                // onClick: () =>
                //   changeRoute('/dashboard/return-shipments-records'),
                label: 'Record',
                icon: ReturnMinor,
                disabled:
                  auth?.user?.role !== 'admin' &&
                  !auth?.user?.roles.includes('get-orders-return')
                    ? true
                    : false,
              },
            ],
          },
          {
            url: '/dashboard/send-message',
            // onClick: () => changeRoute('/dashboard/send-message'),
            label: 'Send Message',
            icon: SendMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('csr-mobile-messages')
                ? true
                : false,

            subNavigationItems: [
              {
                url: '/dashboard/sms-record',
                // onClick: () => changeRoute('/dashboard/sms-record'),
                label: 'Mobile SMS',
                icon: ChatMajor,
                disabled:
                  auth?.user?.role !== 'admin' &&
                  !auth?.user?.roles.includes('csr-mobile-message-record')
                    ? true
                    : false,
              },
            ],
          },
          {
            url: '/dashboard/translations',
            // onClick: () => changeRoute('/dashboard/translations'),
            label: 'Products',
            icon: LanguageMinor,

            subNavigationItems: [
              {
                url: '/dashboard/ingredients',
                // onClick: () => changeRoute('/dashboard/ingredients'),
                label: 'Ingredients',
                icon: AddProductMajor,
                disabled:
                  auth?.user?.role !== 'admin' &&
                  !auth?.user?.roles.includes('ingredients')
                    ? true
                    : false,
              },
            ],
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('translations')
                ? true
                : false,
          },
          {
            url: '/dashboard/abandoned',
            // onClick: () => changeRoute('/dashboard/abandoned'),
            label: 'Abandoned Cart',
            icon: AbandonedCartMajor,
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('abandoned')
                ? true
                : false,
          },
          {
            url: '/dashboard/short-url',
            // onClick: () => changeRoute('/dashboard/short-url'),
            label: 'Short URL',
            icon: LinkMinor,

            subNavigationItems: [
              {
                url: '/dashboard/short-url/settings',
                // onClick: () => changeRoute('/dashboard/short-url/settings'),
                label: 'Settings',
                icon: SettingsMajor,
                disabled:
                  auth?.user?.role !== 'admin' &&
                  !auth?.user?.roles.includes('short-url-settings')
                    ? true
                    : false,
              },
            ],
            disabled:
              auth?.user?.role !== 'admin' &&
              !auth?.user?.roles.includes('short-urls')
                ? true
                : false,
          },
        ]}
      />
      <Navigation.Section
        separator
        title='Settings'
        items={[
          {
            url: '/dashboard/profile',
            // onClick: () => changeRoute('/dashboard/profile'),
            label: 'Profile',
            icon: ProfileMajor,
          },
        ]}
        fill
      />
      <Navigation.Section
        separator
        items={[
          {
            url: '/logout',
            // onClick: () => changeRoute('/logout'),
            label: 'Logout',
            icon: LogOutMinor,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <>
      {!auth.loading && isAuthenticated ? (
        <Frame
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
          navigation={navigationMarkdown}
          topBar={topBarMarkup}
        >
          {children}
        </Frame>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

AppTopBar.prototype = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps)(AppTopBar);
