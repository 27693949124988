import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from '@shopify/polaris';
// import "@shopify/polaris/build/esm/styles.css";
import translations from '@shopify/polaris/locales/en.json';
import App from './App';
import './index.css';
import './styles/chat.css';

ReactDOM.render(
  <AppProvider
    i18n={
      ({
        Polaris: {
          Avatar: {
            label: 'Avatar',
            labelWithInitials: 'Avatar with initials {initials}',
          },
          Frame: { skipToContent: 'Skip to content' },
          TopBar: {
            toggleMenuLabel: 'Toggle menu',
            SearchField: {
              clearButtonLabel: 'Clear',
              search: 'Search',
            },
          },
        },
      },
      translations)
    }
    features={{ newDesignLanguage: true }}
  >
    <App />
  </AppProvider>,
  document.getElementById('root')
);
